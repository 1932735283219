/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

// libraries
import React from 'react';
import { array, string, bool } from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useQuery } from '@apollo/client';
import loadable from '@loadable/component';
import { getBrand } from '../../../../../state/ducks/App/ducks/Brand/Brand-Selectors';
import findURL from '../../../../gql/queries/findURL';
import ErrorBoundary from '../../../ErrorBoundary/ErrorBoundary';
import { getFeatureFlag } from '../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import isProductListingAd from '../../../../helpers/isProductListingAd';
import useIsDisplayChannel from '../../../../helpers/hooks/useIsDisplayChannel';
import useIsPlaPage from '../../../../helpers/hooks/useIsPlaPage';

const  SiteWideBannerRecommendation = loadable(() => import(/* webpackChunkName: "SiteWideBannerRecommendation" */ './SiteWideBannerRecommendation'));
const SiteWideBannerContainer = loadable(() => import(/* webpackChunkName: "SiteWideBannerContainer" */ './SiteWideBannerContainer'));
const PersonalizedSiteWideBannerContainer = loadable(() => import(/* webpackChunkName: "PersonalizedSiteWideBannerContainer" */ '../../EnterpriseID/PersonalizedSiteWideBanner/PersonalizedSiteWideBannerContainer'));
const CountDownBanner = loadable(() => import(/* webpackChunkName: "CountDownBanner" */ '../CountDownBanner/CountDownBanner'));
const OfferBanner = loadable(() => import(/* webpackChunkName: "OfferBanner" */ '../../OfferBanner/OfferBanner'));
const useStyles = makeStyles((theme) => ({
    headerBannersList: {
        /*
            if there is a partnership banner rendering due to exposure to the
            partnership lander, suppress the rendering of other banners.
            (see SitewideBannerUI.js for partnershipBanner logic)
        */
        '&:has(.partnershipBanner)>div:not(:has(.partnershipBanner))': {
            display: 'none',
        },
    },
    eyeBrowContainer: {
        position: 'relative',
        maxWidth: '1432px',
        padding: '0 1rem',
        margin: '0 auto',
        [theme.breakpoints.down(1366)]: {
            padding: '0px',
        },
    },
    bannerWrapper: ({ isMobile }) => ({
        '&:last-child': {
            marginBottom: isMobile ? '1rem' : 0,
        },
    }),
}));
const HeaderBannersList = ({
    banners, url, isEyeBrowShow, isMobile, siteWideBannerPZCampaignId, siteWideBannerContentZones, siteWideBannerFallbackUID, siteWideBannerSkeletonHeight, siteWideBannerSkeletonWidth,
}) => {
    const classes = useStyles({ isMobile });
    const location = useLocation();
    const brand = useSelector(getBrand);
    const FIND_URL_QUERY = findURL(brand.domain, location?.pathname);
    const { data } = useQuery(FIND_URL_QUERY);
    const pageType = data?.findURL?.type || 'notfound';
    const contentType = data?.findURL?.contentType || 'notfound';
    const ffIsMABSitewideBannerV2Enabled = useSelector(getFeatureFlag('is-mab-sitewide-banner-v2-enabled'));
    const isPlaPage = useIsPlaPage();
    const isDisplayChannel = useIsDisplayChannel();
    const urlIsNotExcluded = (bySpecificPath, currentURL) => {
        const pathList = bySpecificPath.filter((item) => item.indexOf('*') >= 0);
        let urlFound = false;
        pathList.forEach((path) => {
            const specificPath = path.split('*')?.[0];
            if (currentURL.indexOf(specificPath) >= 0) {
                urlFound = true;
            }
        });
        return urlFound;
    };
    const isPlaPagesEnabled = isPlaPage || isDisplayChannel || isProductListingAd(location?.search);
    const pageIsNotExcluded = (exclusions) => {
        let currentUrl = location?.pathname || '/';
        if (typeof window !== 'undefined') currentUrl = url || window.location.pathname || '/';
        if (exclusions?.byPageType?.includes('pla') && isPlaPagesEnabled) {
            return false;
        }
        if (exclusions?.byPageType?.includes('searchterm') && currentUrl?.includes('searchterm')) {
            return false;
        }
        if (!pageType || (pageType === 'notfound' && !currentUrl?.includes('searchterm'))
            || exclusions.byPageType.includes(pageType)
            || exclusions.byPageType.includes(contentType)
            || exclusions.bySpecificPath.includes(currentUrl)
            || urlIsNotExcluded(exclusions.bySpecificPath, currentUrl)
        ) {
            // we are showing banner according to adding the URL in cms
            if (exclusions.bySpecificPathInclusion.includes(currentUrl)) {
                return true;
            }
            if (exclusions?.byPageType?.includes(pageType) || exclusions?.byPageType?.includes(contentType)) {
                if (isPlaPagesEnabled) {
                    return true;
                }
                return false;
            }
            return false;
        }
        return true;
    };

    const renderBanner = (block) => {
        const contentTypeUid = block._content_type_uid;
        const fullWidth = block.styling?.full_width || false;
        switch (contentTypeUid) {
            case 'countdown_banner':
                return <div className={`${isEyeBrowShow ? '' : classes.eyeBrowContainer}`}><OfferBanner menu={block} /></div>;
            case 'countdown_banner_v2':
                return <div className={`${isEyeBrowShow || fullWidth ? '' : classes.eyeBrowContainer}`}><ErrorBoundary boundaryName="Header Banner" errorMessage="Something unexpected occurred in CountDownBanner"><CountDownBanner data={block} /></ErrorBoundary></div>;
            case 'sitewide_banner_v2': {
                if (ffIsMABSitewideBannerV2Enabled) {
                    return <ErrorBoundary boundaryName="Header Banner" errorMessage="Something unexpected occurred in the PersonalizedSiteWideBannerContainer"><PersonalizedSiteWideBannerContainer bannerData={block} pz_campaign_id={siteWideBannerPZCampaignId} content_zones={siteWideBannerContentZones} skeleton_height={siteWideBannerSkeletonHeight} skeleton_width={siteWideBannerSkeletonWidth} contentType={contentTypeUid} fallbackUID={siteWideBannerFallbackUID} /></ErrorBoundary>;
                }
                return <ErrorBoundary boundaryName="Header Banner" errorMessage="Something unexpected occurred in the SiteWideBannerContainer"><SiteWideBannerContainer bannerData={block} /></ErrorBoundary>;
            }
            case 'sitewide_banner_product_recommender':
                return <ErrorBoundary boundaryName="Header Banner" errorMessage="Something unexpected occurred in the SiteWideBannerRecommendation"><SiteWideBannerRecommendation bannerData={block?.banner} displayOptions={block?.display_options} /></ErrorBoundary>;
            default:
                return '';
        }
    };

    return (
        <div className={classes.headerBannersList}>
            { banners?.map((block) => {
                const exclusions = {
                    byPageType: block.bulk_exceptions || block.display_options?.bulk_exceptions || [],
                    bySpecificPath: block.granular_exceptions ||  block.display_options?.granular_exceptions || [],
                    bySpecificPathInclusion: block.granular_inclusion || block.display_options?.granular_inclusion || [],
                };
                const isPageNotExcluded = pageIsNotExcluded(exclusions);
                const getClassName = () => {
                    if (isEyeBrowShow) {
                        return '';
                    }
                    if (!isPageNotExcluded) {
                        return '';
                    }
                    return `${classes.bannerWrapper}`;
                };
                return <div className={getClassName()}>{isPageNotExcluded && renderBanner(block)}</div>;
            },
            )}
        </div>
    );
};

HeaderBannersList.defaultProps = {
    banners: [],
    url: null,
    isEyeBrowShow: false,
    isMobile: false,
    siteWideBannerPZCampaignId: null,
    siteWideBannerContentZones: [],
    siteWideBannerFallbackUID: null,
    siteWideBannerSkeletonHeight: null,
    siteWideBannerSkeletonWidth: null,
};

HeaderBannersList.propTypes = {
    banners: array,
    url: string,
    isEyeBrowShow: bool,
    isMobile: bool,
    siteWideBannerPZCampaignId: string,
    siteWideBannerContentZones: array,
    siteWideBannerFallbackUID: string,
    siteWideBannerSkeletonHeight: string,
    siteWideBannerSkeletonWidth: string,
};

export default HeaderBannersList;
